import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Form, Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

import { ReactComponent as ProfileImage } from "../../../../assets/Profile_Photo.svg";
import { apiCall } from "../../../../utils/apiCall";
import config from "../../../../config";
import { updateLoginUserDetail } from "../../../../redux/user/user.action";
import { updateActiveNavItem } from "../../../../redux/sideMenu/menu.action";
import useAlert from "../../../../utils/useAlert";
import toasterConstant from "../../../../constant/messages/toaster.json";
import {
  toValidateEmail,
  toValidatePhone,
  toValidateFullName,
} from "../../../../utils/validations/checkValidation";
import { getUserDetailById } from "../../../../redux/users/action";
import ModalComponent from "../../../../components/ModalPopupComponent/ModalPopup.component";

import GeneralInputField from "../../Profile/generalSetting/GeneralComponent/GeneralInput.component";

import {
  EditProfileContainer,
  ImageAvatar,
  ChangeImage,
  InputProfileDivCont,
  PhoneErrorDiv,
  MFAWrapper,
  SwitchWrapper,
  SettingsWrapper,
  AccountProfileContainer,
  DivScroller,
  InputProfileDiv,
  InputWrapper,
  ButtonWrapper,
  ButtonDiv,
  ContainerImage,
  LabelContainer,
  LeftLabel,
  SpanTop,
  SpanBottom,
  Divider,
  DropWrapper,
  ModalBodyHeight,
  MFADropWrapper,
  CameraIcon,
} from "./UserProfile.component.styles";
import InputField from "../../../../components/InputFieldComponent/InputField.component";
import Button from "../../../../components/Button/Button.component";
import SwitchComponent from "../../../../components/Switchbox/SwitchComponent";
import SimpleDropdownFilter from "../../../../components/SimpleDropdownFilter.component";
import SettingsHeader from "../../Profile/generalSetting/GeneralSettingHeader/GeneralSettingHeader.component";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import styled from "styled-components";

const { user_profile_update, user_profile_update_error, email_already_in_use } = toasterConstant;
const s3BucketProfileImg = config.s3BucketProfileImg;
// Styled components
const AvatarImage = styled.img`
  max-width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledProfileImage = styled(ProfileImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  &.icon-svg {
    width: 100%;
    height: 100%;
  }
`;

function EditProfile({
  userData,
  updateLoginUserDetail,
  setFace,
  updateActiveNavItem,
  getUserDetailById,
  userId,
  accountMFAData,
  userOverview,
  isSuperAdmin,
}) {
  const inputRef = useRef();
  const [validated, setValidated] = useState(false);
  const fullNameInputRef = useRef(null);
  const [phoneError, setPhoneError] = useState(false);
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const mfaData = userOverview?.mfaData;

  const [state, setState] = useState({
    fullName: "",
    email: "",
    phone: "",
    imgData: "",
  });

  /*   const [formErrors, setFormErrors] = useState({
    fullNameError: "",
    emailError: "",
    phoneError: "",
  }); */

  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [crop, setCrop] = useState({ aspect: 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState("");
  const [imageRef, setImageRef] = useState(null);

  const [mfadataLocal, setMfadataLocal] = useState({});
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showCropModal, setShowCropModal] = useState(false); // State to control the crop modal

  useEffect(() => {
    getUserDetailById(userId);
  }, [userId]);

  useEffect(() => {
    setMfadataLocal(mfaData);
  }, [mfaData]);

  useEffect(() => {
    if (userData) {
      setState({
        fullName: userData?.fullName,
        email: userData?.email,
        phone: userData?.phone,
        imgData: userData?.imgData,
        ...userData,
      });
    }
  }, [userData, editMode]);

  useEffect(() => {
    let error = toValidatePhone(state?.phone);
    setPhoneError(error);
  }, [state]);

  const presubmitCheck = (e) => {
    e.preventDefault();
    if (userData?.email !== state.email) {
      setShowPasswordModal(true);
    } else {
      handleSubmit(e);
    }
  };

  const handleSubmit = (e, isEmailChanged, password) => {
    e.preventDefault();
    setValidated(true);
    if (phoneError) {
      return;
    }
    /*  if (isFormValid()) { */
    let formData = new FormData();
    let { fullName, email, phone } = state;
    if (isEmailChanged) {
      formData.append(
        "userData",
        JSON.stringify({
          fullName,
          email,
          phone,
          password,
          mfaData: {
            status: mfadataLocal?.status,
            type: "EMAIL",
          },
        })
      );
    } else {
      formData.append(
        "userData",
        JSON.stringify({
          fullName,
          email,
          phone,
          mfaData: {
            status: mfadataLocal?.status,
            type: "EMAIL",
          },
        })
      );
    }

    if (croppedImageUrl) {
      fetch(croppedImageUrl)
        .then((res) => res.blob())
        .then((blob) => {
          formData.append("profileImg", blob, "profile.jpg");
          const url = isEmailChanged ? `/user/updateprofile/${userData._id}` : `/user/${userData._id}`;

          apiCall(url, {
            method: "PUT",
            data: formData,
            type: "file",
          })
            .then(({ data }) => {
              if (data.success) {
                setValidated(false);
                if (data) {
                  let { imgData } = data.data;
                  updateLoginUserDetail({ fullName, email, phone, imgData });
                }
                showAlert("success", t(user_profile_update));
                setIsLoading(false);
                setEditMode(false);
                setShowPasswordModal(false);
                setInvalidPasswordError(false);
                setUserPassword("");
              } else {
                if (data.message.includes("Password is invalid")) {
                  setInvalidPasswordError(true);
                } else if (data.message.includes("Email not available")) {
                  showAlert("error", t(email_already_in_use));
                } else {
                  showAlert("error", t(user_profile_update_error));
                }
              }
            })
            .catch((err) => {
              showAlert("error", t(user_profile_update_error));
              setIsLoading(false);
            });
        });
    } else {
      const url = isEmailChanged ? `/user/updateprofile/${userData._id}` : `/user/${userData._id}`;

      apiCall(url, {
        method: "PUT",
        data: formData,
        type: "file",
      })
        .then(({ data }) => {
          if (data.success) {
            setValidated(false);
            if (data) {
              let { imgData } = data.data;
              updateLoginUserDetail({ fullName, email, phone, imgData });
            }
            showAlert("success", t(user_profile_update));
            setIsLoading(false);
            setEditMode(false);
            setShowPasswordModal(false);
            setInvalidPasswordError(false);
            setUserPassword("");
          } else {
            if (data.message.includes("Password is invalid")) {
              setInvalidPasswordError(true);
            } else if (data.message.includes("Email not available")) {
              showAlert("error", t(email_already_in_use));
            } else {
              showAlert("error", t(user_profile_update_error));
            }
          }
        })
        .catch((err) => {
          showAlert("error", t(user_profile_update_error));
          setIsLoading(false);
        });
    }
    /*   } */
  };
  const [previousImageUrl, setPreviousImageUrl] = useState("");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Store the previous image URL before setting a new one
      if (state.imgData) {
        setPreviousImageUrl(`${s3BucketProfileImg}${state.imgData}`);
      }
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setCroppedImageUrl(reader.result);
        setShowCropModal(true); // Open the crop modal

        setCrop({
          unit: "%",
          x: 0,
          y: 0,
          width: 50,
          height: 50,
        });
        setImageRef(null); // Reset image reference
        setCompletedCrop(null); // Reset completed crop
      };
      reader.readAsDataURL(file);

      // Reset the input value to allow re-selection of the same file
      e.target.value = null;
    }
  };

  const onImageLoaded = (image) => {
    setImageRef(image);
  };

  const onCropComplete = (crop) => {
    setCompletedCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    setCrop(crop);
  };

  const getCroppedImage = () => {
    if (imageRef && completedCrop.width && completedCrop.height) {
      const canvas = document.createElement("canvas");
      const scaleX = imageRef.naturalWidth / imageRef.width;
      const scaleY = imageRef.naturalHeight / imageRef.height;
      canvas.width = completedCrop.width;
      canvas.height = completedCrop.height;
      const ctx = canvas.getContext("2d");

      ctx.drawImage(
        imageRef,
        completedCrop.x * scaleX,
        completedCrop.y * scaleY,
        completedCrop.width * scaleX,
        completedCrop.height * scaleY,
        0,
        0,
        completedCrop.width,
        completedCrop.height
      );

      return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (blob) {
            const croppedUrl = URL.createObjectURL(blob);
            setCroppedImageUrl(croppedUrl);
            resolve(croppedUrl);
          } else {
            reject(new Error("Canvas is empty"));
          }
        }, "image/jpeg");
      });
    }
  };

  const handleCloseCropModal = () => {
    setCroppedImageUrl(previousImageUrl); // Restore the previous image
    setShowCropModal(false);
  };

  useEffect(() => {
    updateActiveNavItem("userProfile");
  }, [updateActiveNavItem]);

  const [userPassword, setUserPassword] = useState("");
  const [invalidPasswordError, setInvalidPasswordError] = useState(false);
  const [userAlert, setUserAlert] = useState(null);

  const EmailChangeBody = () => {
    return (
      <>
        <span>Please enter your password to confirm the email change</span>
        <InputField
          required
          label="Password"
          name={"password"}
          type="password"
          placeholder={t("Enter your password")}
          value={userPassword}
          onChange={(e) => {
            setUserAlert(null);
            setInvalidPasswordError(false);
            setUserPassword(e.target.value);
          }}
          isError={invalidPasswordError || !!userAlert}
          errorMessage={userAlert ? userAlert : "Incorrect password. Please try again."}
          autoFocus
          autocomplete="one-time-code"
        />
      </>
    );
  };

  return (
    <SettingsWrapper>
      <SettingsHeader title={t("USER_PROFILE_HEADER")} />
      <EditProfileContainer>
        <Form id="changeProfileForm" noValidate className="no-flex">
          <AccountProfileContainer>
            <DivScroller isSuperAdmin={isSuperAdmin}>
              <InputProfileDiv>
                <>
                  <ContainerImage>
                    <LabelContainer>
                      <LeftLabel>
                        <SpanTop>
                          <Trans>Profile Photo</Trans>
                        </SpanTop>
                        <SpanBottom>
                          <Trans>Your photo for the dashboard</Trans>
                        </SpanBottom>
                      </LeftLabel>
                    </LabelContainer>

                    <>
                      <ImageAvatar>
                        {croppedImageUrl ? (
                          <AvatarImage src={croppedImageUrl} alt="Profile" />
                        ) : state.imgData ? (
                          <AvatarImage src={s3BucketProfileImg + state.imgData} alt="Profile" />
                        ) : (
                          <StyledProfileImage className="icon-svg" />
                        )}
                        {editMode && (
                          <ChangeImage onClick={() => inputRef?.current?.click?.()}>
                            <CameraIcon className="icon icon-camera" />
                            <span>
                              <Trans>
                                {croppedImageUrl || state.imgData ? t("Change image") : t("Add image")}
                              </Trans>
                            </span>
                            <input
                              type="file"
                              ref={inputRef}
                              accept="image/*"
                              onChange={handleImageChange}
                              disabled={!editMode}
                            />
                          </ChangeImage>
                        )}
                      </ImageAvatar>
                    </>
                  </ContainerImage>

                  <ModalComponent
                    size="lg"
                    centered
                    backdrop="static"
                    title={t("Crop Image")}
                    handleClose={handleCloseCropModal}
                    show={showCropModal}
                  >
                    <ModalBodyHeight>
                      <Modal.Body>
                        {croppedImageUrl && (
                          <ReactCrop
                            src={croppedImageUrl}
                            crop={crop}
                            onImageLoaded={onImageLoaded}
                            onComplete={onCropComplete}
                            onChange={onCropChange}
                          />
                        )}
                      </Modal.Body>
                    </ModalBodyHeight>
                    <Modal.Footer>
                      <Button
                        label={t("Cancel")}
                        showIcon={false}
                        buttonType="ghost"
                        onClick={handleCloseCropModal}
                      />
                      <Button
                        showIcon
                        iconClass="save"
                        buttonType="save"
                        label={t("Done")}
                        onClick={async () => {
                          await getCroppedImage();
                          setShowCropModal(false);
                        }}
                      />
                    </Modal.Footer>
                  </ModalComponent>
                  <Divider />
                </>

                <InputProfileDivCont>
                  <InputWrapper>
                    <GeneralInputField
                      fullNameInputRef={fullNameInputRef}
                      lineDivider={true}
                      leftLabel={t("First and Last Name")}
                      rightLabel={t("Your full name")}
                      required
                      placeholder={t("First and Last Name")}
                      variant="standard"
                      type="text"
                      id="fullName"
                      name="fullName"
                      value={state?.fullName}
                      disabled={!editMode}
                      onChange={(e) => {
                        setState({
                          ...state,
                          fullName: e.target.value,
                        });
                      }}
                      isError={validated && toValidateFullName(state?.fullName)}
                      errorMessage={validated && toValidateFullName(state?.fullName)}
                    />
                  </InputWrapper>
                </InputProfileDivCont>
                <InputProfileDivCont>
                  <InputWrapper>
                    <GeneralInputField
                      lineDivider={true}
                      leftLabel={t("Email")}
                      rightLabel={t("Contact email ID of the user")}
                      required
                      id="email"
                      placeholder={t("Email")}
                      variant="standard"
                      type="email"
                      name="email"
                      value={state?.email}
                      disabled={!editMode}
                      autocomplete="new-password"
                      pattern={
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                      }
                      onChange={(e) => {
                        setState({
                          ...state,
                          email: e.target.value,
                        });
                      }}
                      isError={validated && toValidateEmail(state?.email)}
                      errorMessage={validated && toValidateEmail(state?.email)}
                    />
                  </InputWrapper>
                </InputProfileDivCont>
                <InputProfileDivCont>
                  <InputWrapper>
                    <GeneralInputField
                      leftLabel={t("Phone Number")}
                      rightLabel={t("Contact number of the user")}
                      required
                      id="phone"
                      placeholder={t("Phone Number")}
                      variant="standard"
                      type="phone"
                      name="phone"
                      value={state?.phone}
                      disabled={!editMode}
                      pattern={
                        /^(?=(?:\D*\d){10,15}\D*$)\+?[0-9]{1,3}[\s-]?(?:\(0?[0-9]{1,5}\)|[0-9]{1,5})[-\s]?[0-9][\d\s-]{5,7}\s?(?:x[\d-]{0,4})?$/
                      }
                      onChange={(e) => {
                        setState({
                          ...state,
                          phone: e.target.value,
                        });
                      }}
                    />
                  </InputWrapper>
                </InputProfileDivCont>
                <>
                  {accountMFAData?.status && (
                    <>
                      <Divider />
                      <MFAWrapper>
                        <InputProfileDivCont>
                          <InputWrapper>
                            <SwitchWrapper>
                              <SpanTop>
                                <Trans>Multi-Factor Authentication</Trans>
                              </SpanTop>

                              <SwitchComponent
                                onChange={(e) => {
                                  setMfadataLocal({
                                    ...mfadataLocal,
                                    status: e.target.checked,
                                  });
                                }}
                                checked={mfadataLocal?.status}
                                disabled={!editMode || accountMFAData?.isMandatory}
                              />
                            </SwitchWrapper>

                            {mfadataLocal?.status && (
                              <MFADropWrapper>
                                <SpanTop>
                                  <Trans>MFA Type</Trans>
                                </SpanTop>
                                <DropWrapper>
                                  <SimpleDropdownFilter
                                    useAsDropdown={true}
                                    options={[
                                      {
                                        _id: 1,
                                        label: t("Email"),
                                      },
                                    ]}
                                    disabled={true}
                                  />
                                </DropWrapper>
                              </MFADropWrapper>
                            )}
                          </InputWrapper>
                        </InputProfileDivCont>
                      </MFAWrapper>
                    </>
                  )}
                </>

                <PhoneErrorDiv>{validated && <p className="text-danger">{phoneError}</p>}</PhoneErrorDiv>
              </InputProfileDiv>
            </DivScroller>
            {!editMode ? (
              <ButtonWrapper>
                <ButtonDiv>
                  <Button
                    label={t("Edit")}
                    iconClass="edit"
                    onClick={(e) => {
                      e.preventDefault();
                      setEditMode(true);
                    }}
                    showIcon
                    buttonType="secondary"
                  />
                </ButtonDiv>
              </ButtonWrapper>
            ) : (
              <ButtonWrapper>
                <ButtonDiv style={{ marginRight: "15px" }}>
                  <Button
                    label={t("Cancel")}
                    onClick={() => {
                      setSelectedFile(null);
                      setEditMode(false);
                    }}
                    showIcon={false}
                    buttonType="ghost"
                  />
                </ButtonDiv>
                <ButtonDiv>
                  <Button
                    label={t("Save")}
                    iconClass="save"
                    disabled={isLoading}
                    onClick={presubmitCheck}
                    showIcon
                    buttonType="save"
                  />
                </ButtonDiv>
              </ButtonWrapper>
            )}
          </AccountProfileContainer>
        </Form>
        <ModalComponent
          show={showPasswordModal}
          variant="TransactionalModal"
          handleClose={() => {}}
          message="Confirm Email Change"
          paragraph={<EmailChangeBody />}
          button1Text="Confirm"
          button2Text="Cancel"
          onClickA={(e) => {
            if (userPassword?.trim()) {
              handleSubmit(e, true, userPassword);
            } else {
              setUserAlert("Please enter a valid password.");
            }
          }}
          onClickB={() => {
            setSelectedFile(null);
            setEditMode(false);
            setShowPasswordModal(false);
            setUserPassword("");
            setInvalidPasswordError(false);
          }}
          typeA="primary"
          typeB="ghost"
        />
      </EditProfileContainer>
    </SettingsWrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state?.user?.loginDetails?.userData,
    mfaData: state?.user?.loginDetails?.userData.mfaData,
    userId: state?.user?.loginDetails?.userData?._id,
    accountMFAData: state?.user?.loginDetails?.mfaData,
    userOverview: state?.users?.UserOverview,
    isSuperAdmin: state.user.isSuperAdmin,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateLoginUserDetail: (data) => dispatch(updateLoginUserDetail(data)),
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  getUserDetailById: (id) => dispatch(getUserDetailById(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
