// import { CustomIssacLRUCache } from "./cachedrivers/CustomIssacLRUCache";
// import { CustomMemCache } from "./cachedrivers/CustomMemCache";
import { CustomRSMSLruCache } from "./cachedrivers/CustomRSMSLRUCache";
import { LForageCache } from "./cachedrivers/LForageCache";
// import { LSCache } from "./cachedrivers/LsCache";
import { CacheTypes } from "./CacheTypes";
import { CacheRefs } from "./CacheRefs";
export class CacheManager {
  static instance = null;
  inMenCache = null;
  fileCache = null;
  cache = this.fileCache; //this can be swapped out for any cache implementa
  defaultTTL = 300000; //ttl in millis default val is 5mins = 300,000 secs

  static getInstance(cacheTypeArg) {
    if (CacheManager.instance == null) {
      CacheManager.instance = new CacheManager();
      // CacheManager.instance.inMenCache = new CustomMemCache()
      // CacheManager.instance.inMenCache = new CustomIssacLRUCache()
      CacheManager.instance.inMenCache = new CustomRSMSLruCache();
      CacheManager.instance.fileCache = new LForageCache();
      CacheManager.instance.autoPurgeIfReq();
    }
    CacheManager.instance.switchCacheType(cacheTypeArg);
    return CacheManager.instance;
  }

  switchCacheType(cacheTypeArg) {
    if (!cacheTypeArg) {
      cacheTypeArg = CacheTypes.IMC; //by default switch to File based cache ...!!
    }
    if (cacheTypeArg === CacheTypes.IMC) {
      CacheManager.instance.cache = CacheManager.instance.inMenCache;
      return CacheManager.instance.inMenCache;
    } else if (cacheTypeArg === CacheTypes.FBC) {
      CacheManager.instance.cache = CacheTypes.fileCache;
      return CacheManager.instance.fileCache;
    } else {
      CacheManager.instance.cache = CacheTypes.inMenCache;
      return CacheManager.instance.inMenCache;
    }
    // console.log("this==", this);
  }

  get = async function (key, cacheType, withPrefix = true) {
    const caheToUse = CacheManager.instance.switchCacheType(cacheType);
    return caheToUse?.getWithExpiry(key, withPrefix);
  };

  set = async function (key, value, argTTL, cacheType, withPrefix = true) {
    if (!argTTL) {
      argTTL = this.defaultTTL;
    }
    const caheToUse = CacheManager.instance.switchCacheType(cacheType);
    return caheToUse?.setWithExpiry(key, value, argTTL, withPrefix);
  };

  remove = async function (key, cacheType, withPrefix = true) {
    const caheToUse = CacheManager.instance.switchCacheType(cacheType);
    return caheToUse.remove(key, withPrefix);
  };

  removeWhere = async function (testFn, cacheType) {
    const caheToUse = CacheManager.instance.switchCacheType(cacheType);
    return await caheToUse?.removeWhere?.(testFn);
  };

  purge = async function (cacheType) {
    try {
      let cacheToUse = CacheManager.instance.switchCacheType(cacheType);
      await cacheToUse.purge(); //based on assumption that IMC is default cache we take here that IMC has been purged if cacheType is null | false
      if (!cacheType) {
        //now to check is FBC purge is required
        let cacheToUse = CacheManager.instance.switchCacheType(CacheTypes.FBC); //this is based on assumption that default cache type is IMC
        await cacheToUse.purge();
      }
    } catch (err) {
      console.log(err);
    }
  };

  // removeWhereKeyIncludes = function (...strArgs) {
  //   return this.cache.removeWhereKeyIncludes.apply(this, strArgs);
  // };

  async removeWhereKeyIncludes(strArgsArr, cacheType = CacheTypes.IMC, withPrefix = false) {
    return await this.removeWhere(function (key, value) {
      if (typeof key === "string" || key instanceof String) {
        for (var i = 0; i < strArgsArr.length; i++) {
          const str = strArgsArr[i];
          if (key.includes(str)) {
            // console.log("keys::Cache::foundMatchFor==" + str);
            return true;
          }
        }
      }
      return false;
    }, cacheType);
  }

  async autoPurgeIfReq() {
    const autoPurgeAlreadyDone = await this.get(CacheRefs.autoPurgeKey(), CacheTypes.FBC);
    if (!autoPurgeAlreadyDone) {
      this.inMenCache.purge();
      this.fileCache.purge();
      this.set(CacheRefs.autoPurgeKey(), true, 1000 * 60 * 60 * 24, CacheTypes.FBC); ///
    }
  }

  keys = async function () {
    return this.cache.keys();
  };

  has = async function (key) {
    return this.cache.has(key);
  };
}
