import React from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import List from "../../../components/List";

// Redux
import { connect } from "react-redux";

import { getAssetTypes, getSelectedAssetType, getAssetTypeDetailById } from "../../../redux/assets/action";

const listType = require("../../../constant/listType");

function AssetTypeList({
  assetTypeList,
  getSelectedAssetType,
  assetTypeOverview,
  filterAccountId,
  getAssetTypes,
  totalCount,
  AssetTypeLoading,
  getAssetTypeDetailById,
  setShowAssetTypeDetail,
}) {
  const { t } = useTranslation();
  const listDetail = {
    label: t("Your Asset Types"),
    emptyListText: t("NO_ASSETS_TYPE_FOUND"),
    listType: listType.ASSET_TYPE,
  };
  const filterByAccount =
    assetTypeList &&
    assetTypeList.filter((assetType) => assetType && assetType.accountId === filterAccountId);

  let filteredAssetType = filterAccountId === "all" ? assetTypeList : filterByAccount;

  return (
    <div className="assetPageList assetPageData assettypename">
      <List
        listDetail={listDetail}
        listData={filteredAssetType}
        selectItem={(id) => {
          getAssetTypeDetailById(id);
          setShowAssetTypeDetail(true);
        }}
        selectedId={assetTypeOverview && assetTypeOverview._id}
        isFilterChange={filterAccountId}
        loadData={getAssetTypes}
        totalCount={totalCount}
        dataLoading={AssetTypeLoading}
      />
    </div>
  );
}
const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  assetTypeList: state.assets.AssetTypes,
  assetTypeOverview: state.assets.AssetTypeOverview,
  totalCount: state.assets.totalCount,
  AssetTypeLoading: state.assets.AssetTypeLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getAssetTypeDetailById: (id) => dispatch(getAssetTypeDetailById(id)),
  getSelectedAssetType: (id) => dispatch(getSelectedAssetType(id)),
  getAssetTypes: (paginationData, isResetState) => dispatch(getAssetTypes(paginationData, isResetState)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetTypeList));
