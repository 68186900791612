import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import { Row, Col, Table, Accordion, Card, Form } from "react-bootstrap";

import DropdownFilter from "../../../components/DropdownFilter.component";

import { Trans, useTranslation } from "react-i18next";

// Button Component
import Button from "../../../components/Button/Button.component";

import { ReactComponent as Download } from "../../../assets/Download.svg";
import { ReactComponent as IconSuccess } from "../../../assets/AddIcon/IconRight.svg";
import { ReactComponent as IconPartial } from "../../../assets/AddIcon/iconPartial.svg";
import { ReactComponent as IconFailure } from "../../../assets/AddIcon/iconFailure.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/AddIcon/Delete Button.svg";
import permissions from "../../../constant/permission";
import * as styles from "../../../view/Dashboard/User/BulkUpload.css";
import { ReactComponent as Information } from "../../../assets/Information_Icon.svg";
import { ReactComponent as FileUploadIcon } from "../../../assets/fileuploadicon.svg";
import Input from "../../../components/Input";

import { getUserBulkUploadTemplates, getUsers } from "../../../redux/users/action";
import {
  getDropdownWorksiteList,
  getDropdownAccountList,
  setDropdownAccountId,
  setDropdownWorksiteId,
} from "../../../redux/dropdownFilters/action";
import SubmitIcon from "../../../assets/AddIcon/SubmitIcon.png";
import alertConstant from "../../../constant/messages/toaster.json";
import validationConstant from "../../../constant/messages/validation.json";
import { apiCall } from "../../../utils/apiCall";
import styled from "styled-components";
import config from "../../../config";
const s3BaseUrl = config.s3BaseUrl;
const baseUrl = config.baseUrl; //Base Url
const csvuploadErrorLogS3 = config.s3BucketUserBulkUploadErrorLog; //URL for user bulk upload csv

const DownloadStyled = styled.div`
  margin-left: 15px;
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  font-size: 18px !important;
`;

const FileUploadCSVICon = styled.div`
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  font-size: 60px !important;
`;

function UserBulkUploadForm({
  dropdownWorksiteList,
  loginDetails,
  getUserBulkUploadTemplates,
  UserBulkUploadTemplates,
  UserBulkUploadLoading,
  handleCancelModal,
  getUsers,
  dropdownAccountList,
  setDropdownAccountId,
  isSuperAdmin,
  isSiteAdmin,
  userTypeDetailList,
}) {
  const { t } = useTranslation();
  const [isDownloadTemplateActive, setIsDownloadTemplateActive] = useState(true);
  const [dragText, setDragText] = useState(t("Please upload .CSV file"));
  const [csvFile, setcsvFile] = useState(null);
  const [worksite, setWorksite] = useState(loginDetails?.userData?.authorizedSites?.[0] || "");
  const [userTypeId, setUserTypeId] = useState("");
  const [csvFileUploadSuccess, setcsvFileUploadSuccess] = useState(false);
  const [isAPIRequestFailed, setisAPIRequestFailed] = useState(false);
  const [isCSVParsedSuccess, setisCSVParsedSuccess] = useState(true);
  const [userAddedCount, setUserAddedCount] = useState(0);
  const [userFailedCount, setUserFailedCount] = useState(0);
  const [userTotalCount, setuserTotalCount] = useState(0);
  const [csvFileUploadingStatus, setcsvFileUploadingStatus] = useState(false);
  const [validated, setValidated] = useState(false);
  const [accountIdLocal, setaccountIdLocal] = useState(loginDetails.accountId);
  const [accountNoLocal, setaccountNoLocal] = useState(loginDetails.accountNo);
  const [errorMessage, seterrorMessage] = useState("");
  const [errorLogCSV, setErrorLogCSV] = useState("");
  const [noFileCsvErrorMsg, setnoFileCsvErrorMsg] = useState("");
  const [isValidationError, setValidationError] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [userTypeList, setUserTypeList] = useState([]);

  const [isAccordianVisible, setisAccordianVisible] = useState(false);
  const csvFileUploadInputRef = useRef(null);

  useEffect(() => {
    console.log(csvFile);
  }, [csvFile]);

  useEffect(() => {
    UserBulkUploadTemplates?.length == 0 && getUserBulkUploadTemplates();
    setUserTypeList(
      userTypeDetailList
        ?.filter((user) => user?.accessLevel == "INDIVIDUAL_EMPLOYEE")
        ?.map((user) => {
          return {
            _id: user?._id,
            label: user?.name,
          };
        })
    );
  }, []);

  useEffect(() => {
    setUserTypeList(
      userTypeDetailList
        ?.filter((user) => user?.accessLevel == "INDIVIDUAL_EMPLOYEE")
        ?.map((user) => {
          return {
            _id: user?._id,
            label: user?.name,
          };
        })
    );
  }, [userTypeDetailList]);

  const validateUserData = (formData) => {
    let accountId = formData.accountIdLocal;
    let userTypeID = formData.userTypeId;
    let siteId = formData.worksite;
    let errors = [];
    if (!userTypeID) {
      errors.push(t("Access Level"));
    }
    if (!accountId && isSuperAdmin) {
      errors.push(t("Account"));
    }
    if (!siteId && !isSiteAdmin) {
      errors.push(t("Worksite"));
    }
    return errors;
  };

  useEffect(() => {
    if (!isSubmitClicked) {
      return;
    }
    let isError = validateUserData({ accountIdLocal, userTypeId, worksite });
    if (isError[0]) {
      setValidationError(isError);
    } else {
      setValidationError(false);
    }
  }, [accountIdLocal, userTypeId, worksite, isSubmitClicked]);

  const handleUploadFormSubmit = (e) => {
    if (isValidationError) {
      e.preventDefault();
      return;
    }
    e.preventDefault();

    let data = new FormData();
    data.append("templatefile", csvFile);
    data.append(
      "templateData",
      JSON.stringify({
        accountId: accountIdLocal || loginDetails?.accountId,
        siteId: isSiteAdmin ? loginDetails.userData.authorizedSites[0] : worksite,
        accountNo: accountNoLocal,
        userTypeId: userTypeId,
      })
    );
    setisAPIRequestFailed(false);
    if ((accountIdLocal || loginDetails?.accountId) && accountNoLocal && csvFile) {
      setcsvFileUploadingStatus(true);
      apiCall(`/bulkupload/uploadOperator/`, { method: "POST", data: data, type: "file" })
        .then((resp) => {
          setcsvFileUploadingStatus(false);
          setcsvFileUploadSuccess(true);
          setisCSVParsedSuccess(resp?.data["success"]);
          setUserAddedCount(resp?.data["Inserted Records"] || 0);
          setUserFailedCount(resp?.data["Failed Records"] || 0);
          setuserTotalCount(resp?.data["Total Records"] || 0);
          setErrorLogCSV(`${csvuploadErrorLogS3}${resp?.data["ErrorLog"]}`);
          if (resp?.data["Inserted Records"] > 0) getUsers({ pageNo: 1, pageSize: 15, searchKey: "" }, true);

          if (!resp?.data?.success) {
            seterrorMessage(resp?.data?.message || "");
          }
          setisAPIRequestFailed(false);
        })
        .catch((err) => {
          setisAPIRequestFailed(true);
          setcsvFileUploadingStatus(false);
          console.log(err);
        });
    } else {
      setValidated(true);
      if (!csvFile) setnoFileCsvErrorMsg(validationConstant.invalid_csv_file);
      // console.log(`AccountID : ${accountIdLocal}`,`Account No : ${accountNoLocal}` , `Worksite : ${worksite} `, csvFile)
    }
  };

  const handleReuploadButton = () => {
    setisAPIRequestFailed(false);
    setcsvFileUploadSuccess(false);
    setWorksite(loginDetails?.userData?.authorizedSites[0] || "");
    setUserTypeId("");
    setaccountIdLocal("");
    setValidated(false);
    setcsvFile(null);
    seterrorMessage("");
  };

  const handleContinueButton = () => {
    handleCancelModal();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragText(t("Drop your file here, or browse"));
    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < e.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === "file") {
          var file = e.dataTransfer.items[i].getAsFile();
          // uploadedFile.push(file)
          const str = file?.name;
          const compareStr = str.slice(str.length - 4, str.length);
          if (compareStr == ".csv") {
            setcsvFile(file);
            setnoFileCsvErrorMsg("");
          } else {
            setcsvFile(null);
            setnoFileCsvErrorMsg(validationConstant.invalid_csv_file);
          }
          // setcsvFile(file)
          // setUploadedFile(uploadedFile)
          console.log("... file[" + i + "].name = " + file.name);
          // console.log(uploadedFile[0], '=====');
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        console.log("... file[" + i + "].name = " + e.dataTransfer.files[i].name);
      }
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragText(t("Drop Here..."));
  };

  const handleAccountFilterChange = (value) => {
    getAccountDetail(value);
    setaccountIdLocal(value);
    setDropdownAccountId(value);
  };

  const handlecsvFileUpload = (e) => {
    console.log(e?.target?.files[0]?.name);
    const str = e?.target?.files[0]?.name;
    const compareStr = str.slice(str.length - 4, str.length);
    if (compareStr == ".csv") {
      setcsvFile(e.target.files[0]);
      setnoFileCsvErrorMsg("");
    } else {
      setcsvFile(null);
      setnoFileCsvErrorMsg(validationConstant.invalid_csv_file);
    }
  };

  const getAccountDetail = (accountId) => {
    // axios.get(`${baseUrl}/account/${accountIdLocal}`)
    apiCall(`/account/${accountId}`)
      .then((resp) => {
        setaccountNoLocal(resp?.data?.data?.accountNo);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {csvFileUploadSuccess || isAPIRequestFailed ? (
        ""
      ) : (
        <div className="button_head_bulk">
          <div className="button_bulk">
            <Button
              label={t("Download Template")}
              className={isDownloadTemplateActive ? "ActiveBulkButton" : "buttonUnsel"}
              onClick={() => setIsDownloadTemplateActive(true)}
              showIcon={false}
              buttonType="primary"
            />
          </div>
          <div className="button_bulk">
            <Button
              label={t("Upload Files")}
              className={!isDownloadTemplateActive ? "ActiveBulkButton" : "buttonUnsel"}
              onClick={() => {
                setIsDownloadTemplateActive(false);
                setcsvFile(null);
              }}
              showIcon={false}
              buttonType="primary"
            />
          </div>
        </div>
      )}
      {isDownloadTemplateActive ? (
        <>
          <Row className="bulkTable">
            <Table className="text-kanit">
              <thead>
                <tr>
                  <th>
                    <Trans>File Name</Trans>
                  </th>
                  <th>
                    <Trans>Description</Trans>
                  </th>
                  <th>
                    <Trans>Action</Trans>
                  </th>
                </tr>
              </thead>
              {UserBulkUploadLoading ? (
                <tr>
                  <td colSpan="3">
                    <p style={{ textAlign: "center", width: "100%" }}>
                      {/* <CircularProgress size={25} style={{ color: "#000", margin: "auto" }} /> */}
                      <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </p>
                  </td>
                </tr>
              ) : (
                <tbody>
                  {UserBulkUploadTemplates?.map((userBulkUploadData) => (
                    <tr className="respo_bulk_tr">
                      <td className="respo_bulk_a">{userBulkUploadData?.name}</td>
                      <td className="respo_bulk_b">{userBulkUploadData?.description || "NA"}</td>
                      <td className="respo_bulk_c">
                        <a
                          style={{ textDecoration: "none" }}
                          target="_blank"
                          href={userBulkUploadData?.link}
                          download="newfilename.pdf"
                        >
                          <DownloadStyled className="icon icon-download" />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
              {/* <tr>
                                    <td>User_Operators</td>
                                    <td>Employees in individual worksites.</td>
                                    <td><Download
                                        style={{
                                            width: 20,
                                            marginRight: 10,
                                            cursor: "pointer",
                                        }}
                                        fill=""
                                    />
                                    </td>
                                </tr> */}
            </Table>
          </Row>
          <Row className="bottom_bulk_row" style={{ width: "100%", margin: "0px" }}>
            <Col className="instruction-bottom" style={{ padding: "0px" }}>
              <Accordion className="text-open-sans accordion_bulk">
                <Card style={{ border: "none", padding: "0px" }}>
                  <Card.Header
                    className={
                      isAccordianVisible ? "bulk_information_header" : "bulk_information_header_white"
                    }
                  >
                    <Accordion.Toggle
                      onClick={() => setisAccordianVisible(!isAccordianVisible)}
                      as={Button}
                      variant="button"
                      tabIndex="0"
                      eventKey="0"
                      className="info_button"
                    >
                      <img src={Information} />
                      <Trans>IMPORTANT INSTRUCTIONS</Trans>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="bulk_information">
                      <div className="line_vertical"></div>
                      <Trans>
                        Please ensure that you download the correct template to enter the details of your
                        users
                      </Trans>
                      .
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
            <Col className="download-top" style={{ padding: "0px" }}>
              {/* <button className="buttonSubmit bulk_submit_button">
                                <>
                                    <Download className="download_svg_bulk" />
                                    <Trans>DOWNLOAD ALL</Trans>
                                </>
                            </button> */}
            </Col>
          </Row>
        </>
      ) : (
        <>
          {isAPIRequestFailed ? (
            <>
              <div className="uploadMessage">
                <h3 className="text-kanit">
                  <Trans>Upload Failed</Trans>
                </h3>
                <p className="rightIcon">
                  <IconFailure />
                </p>
                <p className="text-open-sans text-uploadMessage">
                  <Trans>The file was not uploaded</Trans>.
                </p>
                <p className="text-open-sans userAddedUpload">
                  <span className="redFailed">
                    <Trans>An internal error occurred</Trans>.<br></br>
                    <Trans>Please try uploading again</Trans>.
                  </span>
                </p>
                <br></br>
                <button
                  style={{ marginBottom: "10px" }}
                  className="buttonSubmit bulkUploadYes"
                  onClick={() => handleReuploadButton()}
                >
                  <Trans>TRY AGAIN</Trans>
                </button>
                <br></br>
                <span
                  className="bulkUploadcontinue"
                  onClick={() => handleContinueButton()}
                  style={{ cursor: "pointer" }}
                >
                  <Trans>CONTINUE</Trans>
                </span>
                <br></br>
                <br></br>
                {/* <p><img src={Information} />Please remove the error column from CSV file to avoid data mismatch errors.</p> */}
              </div>
            </>
          ) : csvFileUploadSuccess ? (
            <>
              {userTotalCount == userAddedCount && isCSVParsedSuccess ? (
                <>
                  <div className="uploadMessage">
                    <h3 className="text-kanit">
                      <Trans>Users Added</Trans>
                    </h3>
                    <p className="rightIcon">
                      <IconSuccess />
                    </p>

                    <p className="text-open-sans text-uploadMessage">
                      <Trans>The file was uploaded successfully</Trans>.
                    </p>
                    <p className="text-open-sans userAddedUpload">
                      <Trans>Items successfully added</Trans> :{" "}
                      <span className="successfullAddedS3"> {userAddedCount} </span>
                    </p>
                    <br></br>
                    {/* <p className="text-open-sans userFailedUpload"><span>{userFailedCount} </span>Items <span className="redFailed">failed </span>to add</p> */}
                    <button
                      style={{ marginBottom: "10px" }}
                      className="buttonSubmit bulkUploadYes"
                      onClick={() => handleContinueButton()}
                    >
                      <Trans>CONTINUE</Trans>
                    </button>
                    <br></br>
                    {/* <span onClick={() => handleReuploadButton()} style={{ cursor: "pointer" }}>RE-UPLOAD</span> */}
                    <p className="s3InstructionModal">
                      <Information />
                      <span>
                        <Trans>
                          An email has also been sent to your account with the details of users added
                        </Trans>
                      </span>
                    </p>
                  </div>
                </>
              ) : (
                ""
              )}

              {userTotalCount != userAddedCount && userAddedCount != 0 && isCSVParsedSuccess ? (
                <>
                  <div className="uploadMessage">
                    <h3 className="text-kanit">
                      <Trans>Users Added</Trans>
                    </h3>
                    <p className="rightIcon">
                      <IconPartial />
                    </p>

                    <p className="text-open-sans text-uploadMessage">
                      <Trans>The file was uploaded with some errors</Trans>.
                    </p>
                    <p className="text-open-sans userAddedUpload">
                      <Trans>Items successfully added</Trans> :{" "}
                      <span className="successfullAddedS3"> {userAddedCount} </span>
                    </p>
                    <p className="text-open-sans userFailedUpload">
                      <Trans>Items failed to add</Trans>:{" "}
                      <span className="redFailed">{userFailedCount} </span>
                    </p>
                    <p className="text-open-sans text-uploadMessage">
                      <Trans>Click</Trans>{" "}
                      <a className="clickHereA" href={errorLogCSV} target="_blank">
                        <Trans>here</Trans>
                      </a>{" "}
                      <Trans>to download the CSV file of failed items</Trans>.
                    </p>
                    <button
                      style={{ marginBottom: "10px" }}
                      className="buttonSubmit bulkUploadYes"
                      onClick={() => handleContinueButton()}
                    >
                      <Trans>CONTINUE</Trans>
                    </button>
                    <br></br>
                    <span
                      className="bulkUploadcontinue"
                      onClick={() => handleReuploadButton()}
                      style={{ cursor: "pointer" }}
                    >
                      <Trans>RE-UPLOAD</Trans>
                    </span>
                    <p className="s3InstructionModal">
                      <Information />
                      <span>
                        <Trans>The reasons for errors are listed in the CSV file of failed items</Trans>.
                        <br></br>
                        <Trans>
                          An email has also been sent to your account with the details of the users added
                        </Trans>
                        .<br></br>
                        <Trans>
                          Please remove the error column from the 'failed items' CSV file to avoid errors
                        </Trans>
                        .
                      </span>
                    </p>
                  </div>
                </>
              ) : (
                ""
              )}

              {userTotalCount == userFailedCount && userAddedCount == 0 && isCSVParsedSuccess ? (
                <>
                  <div className="uploadMessage">
                    <h3 className="text-kanit">
                      <Trans>Unable to Add Users</Trans>
                    </h3>
                    <p className="rightIcon">
                      <IconFailure />
                    </p>

                    <p className="text-open-sans text-uploadMessage">
                      <Trans>The users were not added from the file</Trans>
                    </p>
                    <p className="text-open-sans userAddedUpload">
                      <span className="redFailed">
                        <Trans>Uploaded data has errors in it</Trans>.
                      </span>
                    </p>
                    <p className="text-open-sans userAddedUpload">
                      <span className="redFailed">
                        <Trans>Please check for errors and upload again</Trans>.
                      </span>
                    </p>
                    <br></br>
                    <p className="text-open-sans text-uploadMessageFailed">
                      <Trans>Click</Trans>
                      <a className="clickHereA" href={errorLogCSV} target="_blank">
                        {" "}
                        <Trans>here</Trans>{" "}
                      </a>
                      <Trans>to download the CSV file of failed items</Trans>.
                    </p>
                    <button
                      style={{ marginBottom: "10px" }}
                      className="buttonSubmit bulkUploadYes"
                      onClick={() => handleReuploadButton()}
                    >
                      <Trans>RE-UPLOAD</Trans>
                    </button>
                    <br></br>
                    <span
                      className="bulkUploadcontinue"
                      onClick={() => handleContinueButton()}
                      style={{ cursor: "pointer" }}
                    >
                      <Trans>CONTINUE</Trans>
                    </span>
                    <br></br>
                    <br></br>
                    <p className="s3InstructionModal">
                      <Information />
                      <span>
                        <Trans>The reasons for errors are listed in the CSV file of failed items</Trans>.
                        <br></br>
                        <Trans>
                          Please remove the error column from the 'failed items' CSV file to avoid data
                          mismatch errors
                        </Trans>
                      </span>
                    </p>
                  </div>
                </>
              ) : (
                ""
              )}

              {!isCSVParsedSuccess && errorMessage == "Column mismatch" ? (
                <>
                  <div className="uploadMessage">
                    <h3 className="text-kanit">
                      <Trans>Unable to Add Users</Trans>
                    </h3>
                    <p className="rightIcon">
                      <IconFailure />
                    </p>
                    <p className="text-open-sans text-uploadMessage">
                      <Trans>The users were not added from the file</Trans>
                    </p>
                    <p className="text-open-sans userAddedUpload">
                      <span className="redFailed">
                        <Trans>Data mismatch in columns</Trans>.
                      </span>
                    </p>
                    <p className="text-open-sans userAddedUpload">
                      <span className="redFailed">
                        <Trans>Please remove extra data columns and upload again</Trans>.
                      </span>
                    </p>
                    <br></br>
                    <button
                      style={{ marginBottom: "10px" }}
                      className="buttonSubmit bulkUploadYes"
                      onClick={() => handleReuploadButton()}
                    >
                      <Trans>RE-UPLOAD</Trans>
                    </button>
                    <br></br>
                    <span
                      className="bulkUploadcontinue"
                      onClick={() => handleContinueButton()}
                      style={{ cursor: "pointer" }}
                    >
                      <Trans>CONTINUE</Trans>
                    </span>
                    <br></br>
                    <br></br>
                    <p className="s3InstructionModal">
                      <Information />
                      <span>
                        <Trans>
                          Please remove the error column from CSV file to avoid data mismatch errors
                        </Trans>
                        .
                      </span>
                    </p>
                  </div>
                </>
              ) : (
                ""
              )}

              {!isCSVParsedSuccess && errorMessage == "No Records" ? (
                <>
                  <div className="uploadMessage">
                    <h3 className="text-kanit">
                      <Trans>Unable to Add Users</Trans>
                    </h3>
                    <p className="rightIcon">
                      <IconFailure />
                    </p>
                    <p className="text-open-sans text-uploadMessage">
                      <Trans>The users were not added from the file</Trans>
                    </p>
                    <p className="text-open-sans userAddedUpload">
                      <span className="redFailed">
                        <Trans>The uploaded file is empty</Trans>.
                      </span>
                    </p>
                    <br></br>
                    <button
                      style={{ marginBottom: "10px" }}
                      className="buttonSubmit bulkUploadYes"
                      onClick={() => handleReuploadButton()}
                    >
                      <Trans>RE-UPLOAD</Trans>
                    </button>
                    <br></br>
                    <span
                      className="bulkUploadcontinue"
                      onClick={() => handleContinueButton()}
                      style={{ cursor: "pointer" }}
                    >
                      <Trans>CONTINUE</Trans>
                    </span>
                    <br></br>
                    <br></br>
                    <p className="s3InstructionModal">
                      <Information />
                      <span>
                        <Trans>
                          Please enter the information of your employees in this file and upload again
                        </Trans>
                        .
                      </span>
                    </p>
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            <Form noValidate validated={validated} onSubmit={(e) => handleUploadFormSubmit(e)}>
              {isSuperAdmin ? (
                <Row style={{ marginTop: "10px" }}>
                  <Col md={6}>
                    <Input
                      options={dropdownAccountList?.map((data) => ({
                        _id: data._id,
                        label: data.companyName,
                      }))}
                      type="select"
                      as="select"
                      showLabel={true}
                      label={t("Account")}
                      value={accountIdLocal}
                      isValidationError={isValidationError}
                      onChange={(value) => handleAccountFilterChange(value)}
                    />
                    <p className="asterisk_user">*</p>
                  </Col>
                </Row>
              ) : (
                ""
              )}
              <Row style={{ marginTop: "10px" }}>
                <Col md={6}>
                  <Input
                    type="select"
                    options={userTypeList}
                    as="select"
                    showLabel={true}
                    label={t("User Type")}
                    required={true}
                    value={userTypeId}
                    isValidationError={isValidationError}
                    onChange={(value) => {
                      setUserTypeId(value);
                    }}
                    feedback={t("Please select access level")}
                    isInvalid={true}
                    // inputRef={inputRef}
                  />
                  <p className="asterisk_user">*</p>
                </Col>
                {!isSiteAdmin && (
                  <Col md={6}>
                    <Input
                      showLabel={true}
                      label={t("Worksite")}
                      defaultValue={
                        dropdownWorksiteList && dropdownWorksiteList[0] && dropdownWorksiteList[0]._id
                      }
                      required
                      value={worksite}
                      type="select"
                      isValidationError={isValidationError}
                      onChange={(value) => setWorksite(value)}
                      options={dropdownWorksiteList?.map?.((data) => ({
                        _id: data._id,
                        label: data.name,
                      }))}
                      feedback={t("Please select worksite")}
                    />
                    <p className="asterisk_user">*</p>
                  </Col>
                )}
              </Row>
              <Row style={{ marginTop: "10px", marginBottom: "15px" }}>
                <Col md={6}>
                  <label className="userBulkcsv">
                    <Trans>User Bulk Upload CSV Files</Trans>
                  </label>
                  <Card
                    className="text-center p-4 uploadStyleCode"
                    onDrop={(e) => handleDrop(e)}
                    onDragLeave={() => setDragText(t("Drop your file here, or browse"))}
                    onDragOver={(e) => handleDrag(e)}
                    style={
                      noFileCsvErrorMsg == ""
                        ? { border: "2px dashed #0D5FBE" }
                        : { border: "2px dashed #dc3545" }
                    }
                  >
                    {/* <span>{csvFile?.name || ""}</span>*/}
                    <span className="custom-file customFile mt-4 mb-4">
                      <label style={{ cursor: "pointer" }}>
                        <input
                          ref={csvFileUploadInputRef}
                          onChange={(e) => {
                            handlecsvFileUpload(e);
                          }}
                          type="file"
                          accept=".csv"
                          className="d-none"
                          name="uploadFile"
                        />
                        {/*  <NoteAddIcon fontSize='large' />*/}

                        <FileUploadCSVICon className="icon icon-file-upload-csv" />
                      </label>
                    </span>
                    <span className="uploadFileName">
                      <Trans>
                        Drop your file here, or{" "}
                        <span onClick={() => csvFileUploadInputRef?.current?.click()}>browse</span>
                      </Trans>
                    </span>
                    <span className="uploadFileNameFile">
                      <Trans>{dragText}</Trans>
                    </span>
                  </Card>
                  <p style={{ color: "#dc3545" }}>{noFileCsvErrorMsg}</p>
                </Col>
                <Col md={6}>
                  <div className="table-responsive-xl table_TD tableFixHead uploadHight tableMediaRes">
                    <Table striped bordered hove borderless={true}>
                      <thead
                        className="border-0 documentTableH"
                        style={{ fontFamily: "Open Sans", fontSize: "16px", backgroundColor: "#F2F2F2" }}
                      >
                        <tr className="font-weight-bolder">
                          <th>
                            <Trans>Document</Trans>
                          </th>
                          <th>
                            <Trans>Status</Trans>
                          </th>
                          <th>
                            <Trans>Actions</Trans>
                          </th>
                        </tr>
                      </thead>
                      <tbody style={{ fontFamily: "Open Sans", fontSize: "14px" }}>
                        {/* Rendering file details */}
                        {csvFile ? (
                          <>
                            <td data-label="Document">
                              <p className="elipsis_asset">{csvFile.name}</p>
                            </td>
                            <td
                              data-label="Status"
                              style={{ fontFamily: "Open Sans", fontSize: "11px", color: "#919191" }}
                            >
                              <Trans>Selected</Trans>
                            </td>
                            <td data-label="Actions">
                              <div
                                onClick={() => {
                                  setcsvFile(null);
                                  csvFileUploadInputRef.current.value = null;
                                  setnoFileCsvErrorMsg(t("Please upload a valid csv file"));
                                }}
                                className="text-center"
                                style={{ cursor: "pointer" }}
                              >
                                <DeleteIcon />
                              </div>
                            </td>
                          </>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
              <Row className="bottom_bulk_row" style={{ width: "100%", margin: "0px" }}>
                <Col md={8} className="instruction-bottom" style={{ padding: "0px" }}>
                  <Accordion className="text-open-sans accordion_bulk">
                    <Card style={{ border: "none", padding: "0px" }}>
                      <Card.Header
                        className={
                          isAccordianVisible ? "bulk_information_header" : "bulk_information_header_white"
                        }
                      >
                        <Accordion.Toggle
                          onClick={() => setisAccordianVisible(!isAccordianVisible)}
                          as={Button}
                          variant="button"
                          tabIndex="0"
                          eventKey="0"
                          className="info_button"
                        >
                          <Information />
                          <Trans>IMPORTANT INSTRUCTIONS</Trans>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body className="bulk_information">
                          <div className="line_vertical_a" style={{ height: "124px" }}></div>
                          <Trans>
                            Please select the correct access level and the worksite before submitting the
                            files to avoid any errors in the user’s information
                          </Trans>
                          .
                          <span style={{ marginTop: "5px", display: "block" }}>
                            <Trans>
                              In case of Operators, the EmployeeID will become their PIN for login
                            </Trans>
                            .
                          </span>
                          <span style={{ marginTop: "5px", display: "block" }}>
                            <Trans>
                              If the Password/PIN field is left blank, a system generated PIN/Password will be
                              created for that user
                            </Trans>
                            .
                          </span>
                          <span style={{ marginTop: "5px", display: "block" }}>
                            <Trans>Phone number is an optional field</Trans>.
                          </span>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Col>
                <Col className="download-top" style={{ padding: "0px" }}>
                  {/* <Button
                    label={
                      csvFileUploadingStatus ? (
                        <div className="spinner-border" role="status"></div>
                      ) : (
                        <>
                          <Trans>Submit</Trans>
                        </>
                      )
                    }
                    onClick={() => setIsSubmitClicked(true)}
                    showIcon
                    iconClass="save"
                    buttonType="save"
                  /> */}

                  {csvFileUploadingStatus ? (
                    <Button
                      label={<div className="spinner-border" title="Submit" role="status"></div>}
                      showIcon={false}
                      buttonType="save"
                    />
                  ) : (
                    <Button
                      label={t("Submit")}
                      onClick={() => setIsSubmitClicked(true)}
                      showIcon
                      iconClass="save"
                      buttonType="save"
                    />
                  )}
                </Col>
              </Row>
            </Form>
          )}
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  UserBulkUploadTemplates: state.users.UserBulkUploadTemplates,
  UserBulkUploadLoading: state.users.UserBulkUploadLoading,
  dropdownAccountList: state.dropdownFilters.accountList,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  userTypeDetailList: state.userType.userTypeDetailList,
});

const mapDispatchToProps = (dispatch) => ({
  getUserBulkUploadTemplates: (accessType) => dispatch(getUserBulkUploadTemplates(accessType)),
  getUsers: (paginationData, isResetState) => dispatch(getUsers(paginationData, isResetState)),
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserBulkUploadForm));
